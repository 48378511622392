/* globals google */
'use strict';

var base = require('core/storeLocator/storeLocator');
var googleMapsPromise = require('core/thirdParty/googleMaps');

const storeListPageZoomLevel = 8;

/**
 * Uses google maps api to render a map
 */
base.methods.maps = function (zomeLevel) {
    var map;
    var infowindow = new google.maps.InfoWindow();
    var mapdiv = $('.map-canvas').attr('data-locations');
    var bounds = new google.maps.LatLngBounds();
    var zoom = 4;

    mapdiv = JSON.parse(mapdiv);

    if (mapdiv.length === 1) {
        zoom = zomeLevel || 18; // set zoom more specifically if single store
    }

    // Init U.S. Map in the center of the viewport
    var latlng = new google.maps.LatLng(37.09024, -95.712891);
    var mapOptions = {
        scrollwheel: false,
        zoom: zoom,
        center: latlng
    };

    map = new google.maps.Map($('.map-canvas')[0], mapOptions);

    Object.keys(mapdiv).forEach(function (key) {
        var item = mapdiv[key];
        var label = parseInt(key, 10) + 1;
        var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);

        var marker = new google.maps.Marker({
            position: storeLocation,
            type: item.storeType.value,
            map: map,
            title: item.name,
            icon: {
                url: item.backgroundImage,
                size: new google.maps.Size(45, 45),
                scaledSize: new google.maps.Size(45, 45),
                labelOrigin: new google.maps.Point(24, 17)
            },
            label: {
                text: label.toString(),
                color: item.color,
                fontSize: '14px'
            }
        });

        marker.addListener('click', function () {
            infowindow.setOptions({
                content: item.infoWindowHtml
            });
            infowindow.open(map, marker);
        });

        // Create a minimum bound based on a set of storeLocations
        bounds.extend(marker.position);

        // If there is only one store, center the map on that store
        if (mapdiv.length === 1) {
            map.setCenter(storeLocation);
        }
    });
    // Fit the all the store marks in the center of a minimum bounds when any store has been found, unless there is a single store result
    if (mapdiv && mapdiv.length > 1) {
        map.fitBounds(bounds);
    }
}

base.methods.updateMapWithSingleStore = function (locationObj, zoomLevel) {
    var $mapDiv = $('.map-canvas');
    $mapDiv.attr('data-locations', locationObj);

    if ($mapDiv.data('has-google-api')) {
        googleMapsPromise().then(() => base.methods.maps(zoomLevel));
    } else {
        $('.store-locator-no-apiKey').show();
    }
}

base.init = function () {
    if ($('.map-canvas').data('has-google-api')) {
        var isStoreListPage = $('.store-list-container').length;
        googleMapsPromise().then(() => base.methods.maps(isStoreListPage && storeListPageZoomLevel));
    } else {
        $('.store-locator-no-apiKey').show();
    }
};

base.viewStoreDetail = function () {
    $('.store-list-container a[data-toggle="tab"]').on('show.bs.tab', function () {
        var location = $(this).attr('data-location');
        // if link clicked is parent level's state nav
        if (!location) {
            var stateId = $(this).attr('href');
            location = $('.state-tab-content ' + stateId + ' a.active').attr('data-location');
        }
        base.methods.updateMapWithSingleStore(location, storeListPageZoomLevel);
    });
};

module.exports = base;
